import { useState, useEffect } from 'react'

export const useFetch = (url) => {
  const [result, setResult] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(url)
      if (!res.ok) setResult(undefined)
      else {
        let data = await res.json()
        setResult(data)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return result
}