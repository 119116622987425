import React from 'react'
import styles from './styles/Header.module.css'

const Header = ({ image, title }) => {
    return (
        <div className={styles.header}>
            {image && <div className={styles.thumbnail} style={{ backgroundImage: `url(${image})`}}></div>}
            {!image && <div className={styles.thumbnail}></div>}
            <h1 className={styles.title}>{title}</h1>
        </div>
    )
}

export default Header