import React from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from '../utils/useFetch'
import Header from "../components/Header"
import TagList from '../components/TagList'
import styles from "./styles/Project.module.css"

const Project = () => {
  const { id } = useParams()
  const project = useFetch(`${process.env.REACT_APP_DB_URL}/projects/${id}`)

  if (project === null) return "Loading..."
  if (project === undefined) return "Invalid..."

  const image = (project.thumbnail && process.env.REACT_APP_DB_URL + project.thumbnail) || ""
  let key = 0

  return (
    <>
      <div className='limited-width'>
        <Header image={image} title={project.name} />
        {project.link && <a className={styles["external-link"]} href={project.link} target="_blank" rel="noreferrer">View This Project</a>}
        {project.description && project.description.split("\n\n").map((p) => <p className={styles.description} key={++key}>{p}</p>)}
        {project.tags && project.tags.length && <TagList tags={project.tags} />}
      </div>
      <img className={styles.waves} src={`${process.env.REACT_APP_DB_URL}/public/images/layered-waves.svg`} alt="Lilac to purple gradiant waves." />
    </>
  )
}



export default Project