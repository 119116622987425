import { useState, useEffect } from 'react'

export const useLightDark = () => {
  const [theme, setTheme] = useState("dark")

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme])

  return {
    theme: theme, 
    setTheme: setTheme
  }
}