import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFetch } from '../utils/useFetch'
import styles from "./styles/Resume.module.css"

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]

const languages = [
  "C", "C++", "C#", "Python", "Javascript", "NodeJS", "HTML", "CSS",
  "Java", "Scheme", "R", "x86 Assembly", "MatLab"
]

const technologies = [
  "Unity", "MySQL", "MongoDB", "Express", "ReactJS", "NGINX", "AWS",
  "Git", "Photoshop", "Raspberry PI", "Stepper Motor"
]

const Resume = () => {
  const [searchParams, setSearchParams] = useSearchParams({ query: "" })
  const query = searchParams.get("query")
  
  const projects = useFetch(`${process.env.REACT_APP_DB_URL}/projects`)
  const resumes = useFetch(`${process.env.REACT_APP_DB_URL}/resumes`)
  if (projects === null || resumes === null) return "Loading..."
  if (!resumes.length || resumes.length < 1) return "Fatal error..."
  const resume = resumes[0]

  const filteredItems = projects.filter(item => {
    return (item.tags && item.tags.map(t => t.toLowerCase()).filter((t) => t.includes(query)).length > 0)
  })
  filteredItems.sort((a, b) => (new Date(b.start) - new Date(a.start) || new Date(b.end) - new Date(a.end)))

  const filteredLanguages = languages.filter(l => {
    return filteredItems.filter(i => {
      return i.tags.map(t => t.toLowerCase()).includes(l.toLowerCase())
    }).length > 0
  }).concat(["C", "C++", "x86 Assembly"]).sort()
  const otherLanguages = languages.filter(l => !filteredLanguages.includes(l)).sort()

  const filteredTechnologies = technologies.filter(t => {
    return filteredItems.filter(i => {
      return i.tags.map(tag => tag.toLowerCase()).includes(t.toLowerCase())
    }).length > 0
  }).concat(["AWS", "Git", "MySQL"]).sort()
  const otherTechnologies = technologies.filter(t => !filteredTechnologies.includes(t)).sort()

  return (
    <>
      <div className={`${styles["page"]} ${styles["limited-width"]} limited-width`}>
        <div className={styles["page-top"]}>
          <h1 className={styles["header"]}>Resume</h1>
          <p className={styles.description}>To filter my resume for a certain topic click one of the buttons below. If you need a physical copy of my resume for any reason, click the topic you want the resume to include and then either click the print button or print the page from your browser.</p>
          <div className={styles.buttons}>
            <ResumeButton label={"Complete"} setSearchParams={setSearchParams} />
            <ResumeButton label={"Software"} setSearchParams={setSearchParams} />
            <ResumeButton label={"Full-Stack"} setSearchParams={setSearchParams} /><br className='button-break' />
            <ResumeButton label={"Game Dev"} setSearchParams={setSearchParams} />
            <ResumeButton label={"CV"} setSearchParams={setSearchParams} />
            <button className={styles.button} onClick={() => window.print()}>Print</button>
          </div>
          <hr className={styles["page-separator"]} />
        </div>
        <div className={styles["resume"]}>
          <div className={styles.contacts}>
            <h1 className={styles["resume-header"]}>Alex St. Aubin</h1>
            <a className={styles["web-link"]} href={`https://${resume.website}`} target="_blank" rel="noreferrer">Portfolio Website</a>
            <a className={styles["web-link"]} href={`https://${resume.github}`} target="_blank" rel="noreferrer">GitHub</a>
            <a className={styles["web-link"]} href={`https://${resume.linkedin}`} target="_blank" rel="noreferrer">LinkedIn</a>
            <p className={`${styles["item-description"]} ${styles["printed-links"]}`}>
              {resume.website}<br />
              {resume.github}<br />
              {resume.linkedin}
            </p>
            <p className={styles["item-description"]}>
              {resume.location}<br />
              {resume.email}
            </p>
          </div>
          <h1 className={styles["resume-header"]}>Education</h1>
          {resume.education.map(e => <ResumeEducation education={e} key={e.school+e.major} />)}
          {query === "cv" &&
            <>
              <h1 className={styles["resume-header"]}>Teaching and Mentoring</h1>
              {resume.work.filter(w => w.tags.map(t => t.toLowerCase()).includes("cv")).map(j => <ResumeJob job={j} key={j.company_name+j.title} />)}
            </>
          }
          <h1 className={styles["resume-header"]}>Skills</h1>
          <ul className={styles["bullet-list"]}>
            <li><b>Relevant Languages:</b> {filteredLanguages.join(", ")}</li>
            <li><b>Other Languages:</b> {otherLanguages.join(", ")}</li>
            <li><b>Relevant Technologies:</b> {filteredTechnologies.join(", ")}</li>
            <li><b>Other Technologies:</b> {otherTechnologies.join(", ")}</li>
            <li><b>Operating Systems:</b>  MacOS, Linux, Windows</li>
          </ul>
          {query !== "cv" && 
            <>
              <h1 className={styles["resume-header"]}>Work History</h1>
              {resume.work.map(j => <ResumeJob job={j} key={j.company_name+j.title} />)}
              <h1 className={styles["resume-header"]}>Engineering Projects</h1>
              {filteredItems.map(p => <ResumeProject project={p} key={p.name} />)}
            </>
          }
          <h1 className={styles["resume-header"]}>Honors/Awards</h1>
          <ul className={styles["bullet-list"]}>
            {resume.awards.map(a => <ResumeAward award={a} key={a.title} />)}
          </ul>
        </div>
      </div>
      <img className={styles.waves} src={`${process.env.REACT_APP_DB_URL}/public/images/layered-waves.svg`} alt="Lilac to purple gradiant waves." />
    </>
  )
}

function ResumeButton ({ label, setSearchParams }) {
  return <button className={styles.button} onClick={() => setSearchParams({ query: (label.toLowerCase() !== "complete" && label.toLowerCase()) || "" })}>{label}</button>
}

function ResumeEducation ({ education }) {
  const received = new Date(education.received)

  return (
    <div className={styles.item}>
      <p className={styles["item-description"]}>
        {education.school}<br />
        {education.major}
        {education.minor && <><br />{education.minor}</>}
      </p>
      <p className={styles["item-date"]}>
        {`${monthNames[received.getMonth()]} ${received.getFullYear()}`}
      </p>
    </div>
  )
}

function ResumeJob ({ job }) {
  const start = new Date(job.start_date)
  const end = (job.end_date && new Date(job.end_date)) || undefined

  return (
    <div className={styles.item}>
      <div>
        <p className={styles["item-description"]}>
          {job.company_name}<br />
          {job.title}
          {job.subtitle && <><br />{job.subtitle}</>}
        </p>
        <ul className={styles["bullet-list"]}>
          {job.bullets.map(b => <li key={b}>{b}</li>)}
        </ul>
      </div>
      <p className={styles["item-date"]}>
        {`${monthNames[start.getMonth()]} ${start.getFullYear()} - ${(end && `${monthNames[end.getMonth()]} ${end.getFullYear()}`) || "Present"}`}
      </p>
    </div>
  )
}

function ResumeAward ({ award }) {
  const start = new Date(award.start_date)
  const end = (award.end_date && new Date(award.end_date)) || undefined

  return (
    <li>
      <div className={`${styles.item} ${styles.award}`}>
        <p className={styles["item-description"]}>{award.title}</p>
        <p className={styles["item-date"]}>{`${monthNames[start.getMonth()]} ${start.getFullYear()}${(end && ` - ${monthNames[end.getMonth()]} ${end.getFullYear()}`) || ""}`}</p>
      </div>
    </li>
  )
}

function ResumeProject ({ project }) {
  const start = new Date(project.start)
  const end = (project.end && new Date(project.end)) || undefined

  return (
    <div className={styles.item}>
      <div>
        <p className={styles["item-description"]}>
          {project.name}
        </p>
        {project.bullets && <ul className={styles["bullet-list"]}>{project.bullets.map(b => <li key={b}>{b}</li>)}</ul>}
      </div>
      <p className={styles["item-date"]}>
        {`${`${monthNames[start.getMonth()]} ${start.getFullYear()}`} - ${(end && `${monthNames[end.getMonth()]} ${end.getFullYear()}`) || 'present'}`}
      </p>
    </div>
  )
}

export default Resume