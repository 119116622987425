import React from 'react'
import { Link } from 'react-router-dom'
import { useFetch } from '../utils/useFetch'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import { useNavigate } from 'react-router-dom';
import styles from "./styles/Home.module.css"
import "./styles/Home.css"

const Home = () => {
  const navigate = useNavigate();

  const projects = useFetch(`${process.env.REACT_APP_DB_URL}/projects`)
  if (projects == null) return "Loading..."

  projects.sort((a, b) => (new Date(b.start) - new Date(a.start) || new Date(b.end) - new Date(a.end)))
  projects.length = 3

  return (
    <div className={styles.page}>
      <div className={`limited-width ${styles.top}`}>
        <h1 className={styles["home-header"]}>Alex <br className={styles["small-br"]} />St. Aubin</h1>
        <div className="buttons">
          <Link className={styles["cta-button"]} to="/projects?query=video%20game">Play My Games</Link>
          <br className={styles["small-br"]} />
          <br className={styles["small-br"]} />
          <br className={styles["small-br"]} />
          <Link className={styles["cta-button"]} to="/projects?query=website">View My Websites</Link>
        </div>
      </div>
      <img className={styles.waves} src={`${process.env.REACT_APP_DB_URL}/public/images/layered-waves-large.svg`} alt="Lilac to purple gradiant waves." />
      <div className={styles.purple}>
        <div className={`limited-width ${styles["limited-width"]}`}>
        {projects.length &&
          <div>
            <h1 className={styles["bottom-header"]}>Recent Projects</h1>
            <Carousel
              onClickItem={(e) => navigate(`/projects/${projects[e]._id}`)}
              autoPlay={true}
              interval={5000}
              infiniteLoop={true}
              showThumbs={false}
            >
              {projects.map(p =>
                <div className={styles["project-card"]} style={{ backgroundImage: `url(${(p.thumbnail && process.env.REACT_APP_DB_URL + p.thumbnail) || ""})`}} key={p.name}>
                  <h1 className={styles["project-header"]}>{p.name}</h1>
                {/* <div className={styles.thumbnail} style={{ backgroundImage: `url(${(p.thumbnail && process.env.REACT_APP_DB_URL + p.thumbnail) || ""})`}} > */}
                  
                {/* </div> */}
                {/* <img className={styles.thumbnail} src={process.env.REACT_APP_DB_URL + p.thumbnail} /> */}
              </div>)}
            </Carousel>
          </div>
        }
        </div>
      </div>
    </div>
  )
}

export default Home
