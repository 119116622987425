import React from 'react'
import { NavLink } from "react-router-dom";
import { useLightDark } from "../utils/useLightDark"
import styles from "./styles/Footer.module.css"

const Footer = () => {
  const { theme, setTheme } = useLightDark();

  return (
    <footer>
        <div className={`limited-width ${styles["limited-width"]}`}>
          <div className={styles["social-btns"]}>
            <a href="https://github.com/Alex-Saint" target="_blank" rel="noreferrer"><img className={styles["social-btn"]} src={process.env.REACT_APP_DB_URL + "/public/images/github-logo.png"} alt="external link to github" /></a>
            <a href="https://www.youtube.com/@shmeowlex/videos" target="_blank" rel="noreferrer"><img className={styles["social-btn"]} src={process.env.REACT_APP_DB_URL + "/public/images/youtube-logo.png"} alt="external link to youtube" /></a>
            <a href="https://www.linkedin.com/in/alex-st-aubin/" target="_blank" rel="noreferrer"><img className={styles["social-btn"]} src={process.env.REACT_APP_DB_URL + "/public/images/linkedin-logo.png"} alt="external link to linkedin" /></a>
            <a href="https://twitter.com/shmeowlex" target="_blank" rel="noreferrer"><img className={styles["social-btn"]} src={process.env.REACT_APP_DB_URL + "/public/images/twitter-logo.png"} alt="external link to twitter" /></a>
          </div>
          <button className={styles["theme-btn"]} onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
            <div className={theme === "light" ? styles["selected-theme"] : ""}>light</div>
            <span className={styles.separator}>/</span>
            <div className={theme === "dark" ? styles["selected-theme"] : ""}>dark</div>
          </button>
          <div className={styles.legal}>
            <NavLink className={styles["legal-link"]} to="/tos">Terms of Service</NavLink>
            <span className={styles.separator}>|</span>
            <NavLink className={styles["legal-link"]} to="/privacy">Privacy Policy</NavLink>
          </div>
        </div>
    </footer>
  )
}

export default Footer