import { useState } from "react"
import styles from "./styles/Contact.module.css"

const Contact = () => {
  const successMessage = "Successfully sent email!"
  const [email, setEmail] = useState({
    reason: "",
    name: "",
    email: "",
    message: ""
  })
  const [response, setResponse] = useState("")

  const addEmail = async () => {
    let res = await fetch (`${process.env.REACT_APP_DB_URL}/emails`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(email)
    })

    if (res.ok) setResponse(successMessage)
    else{
      if (res.status === 429) setResponse("Error: Too many recent requests. Try again later...")
      else {
        const data = await res.json()
        setResponse(`Error: ${data.message}`)
      }
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    addEmail()
  }

  return (
    <>
      <div className={`${styles["contact-page"]} limited-width`}>
        <h1 className={styles.header}>Contact Me</h1>
        <p className={styles.description}>Like what you see? Fill out the contact form below and I will be sure to get back to you within the next 2 business days.</p>
        <form className={styles.form} onSubmit={e => onSubmit(e)}>
          <label htmlFor="options">Reason:</label>
          <select required name="options" id="options" onChange={e => setEmail({ ...email, reason: e.target.value })}>
            <option value="">Select One...</option>
            <option value="general">General Inquiries</option>
            <option value="web">Full Stack Engineering Inquiries</option>
            <option value="software">Software Engineering Inquiries</option>
            <option value="game">Video Game Development Inquiries</option>
          </select>

          <label htmlFor="name">Name:</label>
          <input required type="name" name="name" id="name" onChange={e => setEmail({ ...email, name: e.target.value })} />
          
          <label htmlFor="email">Email:</label>
          <input required type="email" name="email" id="email" onChange={e => setEmail({ ...email, email: e.target.value })} />
          
          <label htmlFor="message">Message:</label>
          <textarea required name="message" id="message" rows={10} onChange={e => setEmail({ ...email, message: e.target.value })} />

          <input className={styles["submit-btn"]} type="submit" />
        </form>
        <p className={`${styles.description} ${((response === successMessage || response === "") && styles.green) || styles.red}`}>{response}</p>
      </div>
      <img className={styles.waves} src={`${process.env.REACT_APP_DB_URL}/public/images/layered-waves.svg`} alt="Lilac to purple gradiant waves." />
    </>
  )
}

export default Contact
