import React from "react"
import { useState } from "react"
import {  Link, NavLink } from "react-router-dom";
import styles from "./styles/Navbar.module.css"

const Navbar = () => {
  const [navbarState, setNavbarState] = useState(false)
  const updateNavbarState = (s) => setNavbarState(s)
  const closeNavbar = () => updateNavbarState(false)
  const toggleNavbar = () => updateNavbarState(!navbarState)

  return (
    <nav className={styles.navbar}>
      <div className={`limited-width ${styles["limited-width"]} ${styles["navbar-items"]}`}>
        <div className={styles["home-button"]}>
          <Link to="/">
            <img className={styles.logo} src={`${process.env.REACT_APP_DB_URL}/public/images/logo.png`} alt="alex st. aubin's logo" />
          </Link>
        </div>
        
        <button onClick={toggleNavbar} className={styles["hamburger-menu"]}>
          <span className={styles.bar} />
          <span className={styles.bar} />
          <span className={styles.bar} />
        </button>
        <div className={`${styles["navbar-links"]} ${(navbarState && styles.active) || ""}`}>
          <ul>
            <li onClick={closeNavbar}><NavLink to="/projects">Projects</NavLink></li>
            <li onClick={closeNavbar}><NavLink to="/resume">Resume</NavLink></li>
            <li onClick={closeNavbar}><NavLink to="/contact">Contact</NavLink></li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar