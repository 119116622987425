import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from "./styles/TagList.module.css"

const TagList = ({ tags }) => {
  return (
    <>
      <h1 className={styles.header}>Tags</h1>
      <ul className={styles.tags}>
        {tags.map((t) => <Tag tag={t} key={t} />)}
      </ul>
    </>
  )
}

function Tag ({ tag }) {
  const to = tag.toLowerCase().split(" -- ")[0].replace("#", "%23")

  return (
    <NavLink key={tag} to={`/projects?query=${to}`} className={styles.tag}>
      {tag}
    </NavLink>
  )
}

export default TagList