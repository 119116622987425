import React from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useFetch } from '../utils/useFetch'
import TagList from "../components/TagList"
import styles from "./styles/Projects.module.css"

const Projects = () => {
  const [searchParams, setSearchParams] = useSearchParams({ query: "" })
  const query = searchParams.get("query")
  const projects = useFetch(`${process.env.REACT_APP_DB_URL}/projects`)
  if (projects == null) return "Loading..."

  const filteredItems = projects.filter(item => {
    return (item.name.toLowerCase().includes(query) ||
      (item.description &&  item.description.toLowerCase().includes(query)) ||
      (item.tags && item.tags.map(t => t.toLowerCase()).filter((t) => t.includes(query)).length > 0))
  })
  filteredItems.sort((a, b) => (new Date(b.start) - new Date(a.start) || new Date(b.end) - new Date(a.end)))

  const tags = projects.reduce((accumulator, currentValue) => {
    currentValue.tags.forEach(element => {
      if (!accumulator[element]) accumulator[element] = 1
      else accumulator[element] += 1
    });
    return accumulator
  }, {})
  
  const displayedTags = Object.entries(tags).filter((a) => a[1] > 1).sort((a,b) => b[1]-a[1]).map(el=> `${el[0]} -- ${el[1]}`)

  return (
    <>
      <div className={`${styles.page} limited-width`}>
        <h1 className={styles["page-header"]}>Projects</h1>
        <p className={styles.description}>Below is a list of all the projects I have officially worked on as a Software Engineer, Full Stack Engineer, or Game Developer. Use the search bar or click on a tag below to filter the projects for keywords/phrases. Note, only the most popular tags are shown in the tag list below. Use the search bar to search for even more tags.</p>
        <input className={styles.search} type="search" value={query} onChange={e => setSearchParams({ query: e.target.value.toLowerCase() })} />
        <TagList tags={displayedTags} />
        {filteredItems.length === 0 ? "No Projects" : <div className={styles.projects}>{filteredItems.map((p) => <ProjectCard key={p.name} project={p}/>)}</div>}
      </div>
      <img className={styles.waves} src={`${process.env.REACT_APP_DB_URL}/public/images/layered-waves.svg`} alt="Lilac to purple gradiant waves." />
    </>
  )
}

function ProjectCard ({ project }) {
  const image = (project.thumbnail && process.env.REACT_APP_DB_URL + project.thumbnail) || ""

  return (
    <NavLink to={`/projects/${project._id}`} className={styles.card} style={{ backgroundImage: `url(${image})`}}>
      <h1 className={styles.name}>{project.name}</h1>
    </NavLink>
  )
}

export default Projects
